define("ember-scroll-operator/mixins/reset-scroll", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    fastboot: Ember.computed(function () {
      return Ember.getOwner(this).lookup('service:fastboot');
    }),

    /**
     * Scroll to top when route is entered.
     */
    activate: function activate() {
      this._super.apply(this, arguments);

      if (!this.get('fastboot') || !this.get('fastboot.isFastBoot')) {
        (0, _jquery.default)(window).scrollTop(0);
      }
    }
  });

  _exports.default = _default;
});