define('ember-fsm/-machine', ['exports', 'ember-fsm/-transition', 'ember-fsm/-definition', 'ember-fsm/utils'], function (exports, _transition, _definition, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    isTransitioning: false,
    events: null,
    states: null,
    activeTransitions: null,
    currentState: null,
    initialState: null,

    init: function init() {
      var target = this.get('target');
      var events = this.get('events');
      var states = this.get('states');

      if (!target) {
        this.set('target', this);
      }

      if (events && !events.error) {
        events.error = { transition: { $all: 'failed' } };
      }

      this.set('activeTransitions', Ember.A());

      this.definition = new _definition.default({
        states: states,
        events: events
      });

      this.set('stateNames', this.definition.stateNames);
      this.set('eventNames', this.definition.eventNames);
      this.set('currentState', this.get('initialState') || this.definition.initialState);
    },
    send: function send(event) {
      var args = [].slice.call(arguments, 1, arguments.length);
      var fsm = this;
      var transition = void 0;
      var promise = void 0;
      var sameState = void 0;

      if (!(0, _utils.contains)(this.get('eventNames'), event)) {
        throw new Ember.Error('unknown state event "' + event + '" try one of [' + this.get('eventNames').join(', ') + ']');
      }

      transition = this.transitionFor(event, args);
      sameState = transition.toState === this.get('currentState');

      if (this.get('isTransitioning') && !sameState) {
        throw new Ember.Error('unable to transition out of "' + this.get('currentState') + '" ' + ('state to "' + transition.toState + '" state while transitions are ') + ('active: ' + Ember.inspect(this.get('activeTransitions'))));
      }

      promise = transition.perform();

      promise.catch(function (error) {
        fsm.abortActiveTransitions();
        fsm.send('error', {
          error: error,
          transition: transition
        });
      });

      return promise;
    },
    abortActiveTransition: function abortActiveTransition(transition) {
      if (this.hasActiveTransition(transition)) {
        transition.abort();
        this.removeActiveTransition(transition);
      }
    },
    hasActiveTransition: function hasActiveTransition(transition) {
      return (0, _utils.contains)(this.get('activeTransitions'), transition);
    },
    abortActiveTransitions: function abortActiveTransitions() {
      var activeTransitions = this.get('activeTransitions');

      while (activeTransitions.length) {
        activeTransitions.popObject().abort();
      }

      this.set('isTransitioning', false);
    },
    pushActiveTransition: function pushActiveTransition(transition) {
      var activeTransitions = this.get('activeTransitions');

      activeTransitions.pushObject(transition);

      if (activeTransitions.get('length')) {
        this.set('isTransitioning', true);
      }
    },
    removeActiveTransition: function removeActiveTransition(transition) {
      var activeTransitions = this.get('activeTransitions');

      activeTransitions.removeObject(transition);

      if (!activeTransitions.get('length')) {
        this.set('isTransitioning', false);
      }
    },
    checkGuard: function checkGuard(guardProperty, isInverse) {
      var target = this.get('target');
      var guardValue = target.get(guardProperty);
      var result = void 0;

      if (guardValue === undefined) {
        throw new Error('expected guard "' + guardProperty + '" on target' + ('"' + target + '" to be defined'));
      } else if (Ember.typeOf(guardValue) === 'function') {
        result = guardValue.call(this) ? true : false;
      } else {
        result = guardValue ? true : false;
      }

      return isInverse ? !result : result;
    },
    outcomeOfPotentialTransitions: function outcomeOfPotentialTransitions(potentials) {
      var target = this.get('target');
      var potential = void 0;
      var outcomeParams = void 0;
      var i = void 0;

      if (!potentials.length) {
        return null;
      }

      for (i = 0; i < potentials.length; i++) {
        potential = potentials[i];

        if (!potential.isGuarded) {
          outcomeParams = potential;
          break;
        }

        if (potential.doIf && this.checkGuard(potential.doIf)) {
          outcomeParams = potential;
          break;
        }

        if (potential.doUnless && this.checkGuard(potential.doUnless, true)) {
          outcomeParams = potential;
          break;
        }
      }

      if (!outcomeParams) {
        return null;
      }

      outcomeParams.machine = this;
      outcomeParams.target = target;

      return outcomeParams;
    },
    transitionFor: function transitionFor(event, args) {
      var currentState = this.get('currentState');
      var potentials = this.definition.transitionsFor(event, currentState);
      var transitionParams = void 0;

      if (!potentials.length) {
        throw new Ember.Error('no transition is defined for event "' + event + '" ' + ('in state "' + currentState + '"'));
      }

      transitionParams = this.outcomeOfPotentialTransitions(potentials);

      if (!transitionParams) {
        throw new Ember.Error('no unguarded transition was resolved for event ' + ('"' + event + '" in state "' + currentState + '"'));
      }

      transitionParams.eventArgs = args;

      return _transition.default.create(transitionParams);
    },
    inState: function inState(stateOrPrefix) {
      var currentState = this.definition.lookupState(this.get('currentState'));
      var states = this.definition.lookupStates(stateOrPrefix);

      return (0, _utils.contains)(states, currentState);
    },
    canEnterState: function canEnterState(state) {
      var currentState = this.definition.lookupState(this.get('currentState'));
      var potentials = void 0;

      potentials = currentState.exitTransitions.filter(function (t) {
        return t.toState === state;
      });

      return this.outcomeOfPotentialTransitions(potentials) ? true : false;
    },
    _setNewState_: function _setNewState_(transition) {
      this.set('currentState', transition.get('toState'));
    },
    _activateTransition_: function _activateTransition_(transition) {
      this.pushActiveTransition(transition);
    },
    _deactivateTransition_: function _deactivateTransition_(transition) {
      this.removeActiveTransition(transition);
    },


    _setupIsStateAccessors: Ember.on('init', function () {
      var mixin = {};
      var prefixes = this.definition.stateNamespaces.slice(0);
      var properties = [];
      var prefix = void 0;
      var i = void 0;

      function addAccessor(prefix) {
        var property = 'isIn' + (0, _utils.capitalCamelize)(prefix);

        properties.push(property);

        mixin[property] = Ember.computed(function () {
          return this.inState(prefix);
        }).property('currentState');
      }

      for (i = 0; i < this.definition.stateNames.length; i++) {
        prefix = this.definition.stateNames[i];

        if (prefixes.indexOf(prefix) !== -1) {
          continue;
        }

        prefixes.push(prefix);
      }

      for (i = 0; i < prefixes.length; i++) {
        addAccessor(prefixes[i]);
      }

      this.isInStateAccessorProperties = properties;
      this.reopen(mixin);
    })
  });
});