define('ember-chimp/components/ember-chimp', ['exports', 'ember-chimp/lib/default-responses'], function (exports, _defaultResponses) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Logger = Ember.Logger;


  /**
    A utility method for checking the end of a string
    for a certain value.
  
    @method endsWith 
    @param {String} string The string to check. 
    @param {String} suffix The suffix to check the string for.
    @private
    @return {Boolean} A boolean that indicates whether the suffix is present.
  */
  function endsWith(string, suffix) {
    return string.indexOf(suffix, string.length - suffix.length) !== -1;
  }

  /**
    The EmberChimp component provides a simple, flexible
    email list signup form, specifically for integrating
    a Mailchimp form tightly with your Ember CLI Application.
  
    @class EmberChimp
    @module ember-chimp/components/ember-chimp
    @extends Ember.Component
  */
  exports.default = Ember.Component.extend({
    classNames: ['ember-chimp'],
    classNameBindings: ['chimpState'],
    attributeBindings: ['novalidate'],
    novalidate: true,
    tagName: 'form',
    value: '',
    chimpState: 'idle',
    chimpSays: null,
    buttonText: 'Submit',
    loadingText: 'Loading...',
    didSubmitAction: null,
    responses: _defaultResponses.default,
    isLoading: Ember.computed.equal('chimpState', 'loading'),
    ajax: Ember.inject.service(),

    actions: {
      valueDidChange: function valueDidChange() {
        this.setProperties({
          chimpState: 'idle',
          chimpSays: null
        });
      }
    },

    /**
     The action that runs when the form is submitted.
       @method submit
      @param {Event} e The browser's Form Submit event.
    */
    submit: function submit(e) {
      var _this = this;

      e.preventDefault();

      var formAction = Ember.get(this, 'formAction');
      if (!formAction) {
        Logger.error('Ember Chimp: Can not submit without a formAction.');
      }
      formAction = formAction.replace('/post?', '/post-json?').concat('&c=?');

      if (Ember.get(this, 'isLoading')) {
        return;
      }

      if (Ember.get(this, 'value').length === 0) {
        this._triggerInvalid();
        return;
      }

      this.setProperties({
        chimpState: 'loading',
        chimpSays: Ember.get(this, 'loadingText')
      });

      var request = this.makeRequest(formAction).then(function (response) {
        return _this.handleResponse(response);
      }).catch(function () {
        return _this._triggerInvalid();
      });

      if (this.didSubmitAction) {
        this.didSubmitAction(request);
      }
    },


    /**
      An Overwritable Hook for building the request to Mailchimp.
      Uses ember-ajax under the hood.  If you'd like to use a 
      different method to build this request, you can do so
      by overriding this method.
       @method makeRequest
      @param {String} formAction A string for the request submission URL.
      @return {Ember.RSVP.Promise} Returns the request promise.
    */
    makeRequest: function makeRequest(formAction) {
      return Ember.get(this, 'ajax').request(formAction, {
        data: this._buildData(),
        dataType: 'jsonp'
      });
    },


    /**
      An overridable hook for customizing the behavior of Ember Chimp
      when the request returns successfully.
       @method handleResponse
      @param {Object} response The response returned from Mailchimp.
    */
    handleResponse: function handleResponse(response) {
      if (this.isDestroyed) {
        return;
      }
      if (response.result === 'success') {
        this.set('value', '');
      }
      this.setProperties({
        chimpState: response.result,
        chimpSays: this._messageForResponse(response)
      });
    },


    /**
      Returns a user facing string for a response.
       @method _messageForResponse 
      @private
      @param {Object} response The response from the Mailchimp API.
      @return {String} A string to display.
    */
    _messageForResponse: function _messageForResponse(response) {
      var responses = Ember.get(this, 'responses');
      if (response.result === 'success') {
        return responses.success;
      }
      if (!isNaN(parseInt(response.msg.charAt(0)))) {
        return responses.invalidError;
      }
      if (endsWith(response.msg, "(#6592)")) {
        return responses.attemptsError;
      }
      return responses.error;
    },


    /**
     Trigger the component's error state.
       @method _triggerInvalid
      @private
    */
    _triggerInvalid: function _triggerInvalid() {
      if (this.isDestroyed) {
        return;
      }
      this.setProperties({
        chimpSays: Ember.get(this, 'responses.invalidError'),
        chimpState: 'error'
      });
    },


    /**
     Builds the data for the request from the form element.
       @method _buildData
      @private
    */
    _buildData: function _buildData() {
      var data = {};
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this.$().serializeArray()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var item = _step.value;
          data[item.name] = item.value;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return data;
    }
  });
});