define("ember-cli-skip-links/components/skip-link-body", ["exports", "ember-cli-skip-links/templates/components/skip-link-body"], function (_exports, _skipLinkBody) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _skipLinkBody.default,
    tagName: 'div',
    classNameBindings: ['setActiveClass'],
    // optional unique id to match toggle, if missing nest body in toggle and share isActive
    key: '',
    setActiveClass: Ember.computed('isActive', function () {
      if (this.get('isActive')) {
        return this.get('activeClass');
      } else {
        return '';
      }
    }),
    isActive: false,
    renderContent: Ember.computed('prerender', 'forceRerender', 'didRenderOnce', 'isActive', function () {
      if (this.get('forceRerender')) {
        return this.get('isActive');
      } else {
        return this.get('didRenderOnce') || this.get('prerender');
      }
    }),
    prerender: false,
    forceRerender: false,
    didRenderOnce: false,
    // set didRenderOnce in here instead of toggle, move from mixin
    activeClass: 'is-visible',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.mystique.skipLinks.register(this);
    },
    willDestroyElement: function willDestroyElement() {
      this.mystique.skipLinks.unregister(this);
    }
  });

  _exports.default = _default;
});