define("ember-scroll-operator/mixins/scroll-operator", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    _scrollingTimeout: 100,
    fastboot: Ember.computed(function () {
      return Ember.getOwner(this).lookup('service:fastboot');
    }),

    /**
     * Attach on-scroll handler to window/document. Handler will call _scrollTop
     * on scroll.
     */
    activate: function activate() {
      this._super.apply(this, arguments);

      this._attachEvents();
    },

    /**
     * Detach on-scroll handlers on route exit.
     */
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this._detachEvents();
    },

    /**
     * On entering route, decide whether we want to resume previous scrolling
     * position or not based on transition. Also detachEvents to ignore any
     * scrolling that may happen between now and setupController.
     */
    beforeModel: function beforeModel() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var transition = args[0];

      this._super.apply(this, args);

      if (!this._didTransitionViaBackOrForward(transition) && this.controller) {
        this.controller.set('currentPosition', 0);
      }

      this._detachEvents();
    },

    /**
     * Scroll to currentPosition value. Uses run-loop's next helper to ensure it
     * happens after model hooks have been fully executed. Also re-attachEvents
     * now to resume watching scroll position.
     */
    setupController: function setupController() {
      var _this = this;

      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      var controller = args[0];

      this._super.apply(this, args);

      if (controller && (!this.get('fastboot') || !this.get('fastboot.isFastBoot'))) {
        Ember.run.schedule('afterRender', null, function () {
          var currentPosition = Ember.get(controller, 'currentPosition') || 0;
          (0, _jquery.default)(window).scrollTop(currentPosition);

          _this._attachEvents();
        });
      }
    },
    _attachEvents: function _attachEvents() {
      var _this2 = this;

      if (!this.get('fastboot') || !this.get('fastboot.isFastBoot')) {
        var onScroll = function onScroll() {
          var scrollPosition = (0, _jquery.default)(window).scrollTop();
          Ember.run.debounce(_this2, _this2._setScrollTop, scrollPosition, _this2._scrollingTimeout);
        };

        (0, _jquery.default)(document).on('touchmove.scrollable', onScroll);
        (0, _jquery.default)(window).on('scroll.scrollable', onScroll);
      }
    },
    _detachEvents: function _detachEvents() {
      if (!this.get('fastboot') || !this.get('fastboot.isFastBoot')) {
        (0, _jquery.default)(document).off('.scrollable');
        (0, _jquery.default)(window).off('.scrollable');
      }
    },

    /**
     * Determine if transition is triggered by browser forward/back buttons.
     * Credit: https://github.com/emberjs/ember.js/issues/3087
     */
    _didTransitionViaBackOrForward: function _didTransitionViaBackOrForward(transition) {
      return transition && transition.sequence > 1 && transition.hasOwnProperty('urlMethod');
    },

    /**
     * Set currentPosition to $(window).scrollTop value.
     */
    _setScrollTop: function _setScrollTop() {
      var scrollPosition = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

      if (!this.get('fastboot') || !this.get('fastboot.isFastBoot')) {
        this.set('controller.currentPosition', scrollPosition);
      }
    }
  });

  _exports.default = _default;
});