define("ember-cli-skip-links/mixins/skip-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    toggleOverlay: true,
    // this should default to false, but currently doesn't to not break stuff
    activeClass: Ember.computed.alias('mystique.skipLinks.activeClass'),
    isActive: false,
    didRenderOnce: false,
    actions: {
      // TODO: RENAME THIS ACTION!!!!!
      toggleDropdown: function toggleDropdown() {
        if (this.get('isActive')) {
          this.mystique.skipLinks.deactivate();
        } else {
          this.mystique.skipLinks.activate(this);
        }

        return false;
      }
    }
  });

  _exports.default = _default;
});