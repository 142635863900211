define('ember-fsm/-transition', ['exports', 'ember-fsm/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CALLBACKS = ['beforeEvent', '_activateTransition_', 'willExit', 'willEnter', '_setNewState_', 'didExit', 'didEnter', '_deactivateTransition_', 'afterEvent'];

  var EXT_CALLBACK_SOURCES = {
    willExit: 'fromState',
    didExit: 'fromState',
    willEnter: 'toState',
    didEnter: 'toState',
    beforeEvent: 'event',
    afterEvent: 'event'
  };

  exports.default = Ember.Object.extend({
    target: null,
    machine: null,
    fromState: null,
    toState: null,
    event: null,
    eventArgs: null,
    beforeEvent: null,
    willEnter: null,
    didEnter: null,
    willExit: null,
    didExit: null,
    afterEvent: null,
    isAborted: null,
    isResolving: null,
    isResolved: Ember.computed.not('isResolving'),
    isRejected: null,

    init: function init() {
      this.set('resolutions', {});
      this.set('rejections', {});
    },
    abort: function abort() {
      this.set('isAborted', true);
    },
    perform: function perform() {
      var _this = this;

      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        var currentCallbackIndex = 0;

        var next = function next() {
          var cb = CALLBACKS[currentCallbackIndex++];

          if (!cb) {
            resolve(_this);
          } else {
            _this.callback(cb).then(next, reject);
          }
        };

        next();
      });

      this.set('isResolving', true);

      promise.then(function () {
        _this.set('isRejected', false);
      });

      promise.catch(function () {
        _this.set('isRejected', true);
      });

      promise.finally(function () {
        _this.set('isResolving', false);
      });

      return promise;
    },
    callbacksFor: function callbacksFor(transitionEvent) {
      var callbacks = [];
      var machine = this.get('machine');
      var def = machine.definition;
      var target = this.get('target');
      var sources = [this];
      var sourceCallbackNames = void 0;
      var extSource = void 0;
      var source = void 0;
      var callbackVia = void 0;
      var callbackName = void 0;
      var callbackFn = void 0;
      var i = void 0;
      var j = void 0;

      if (extSource = EXT_CALLBACK_SOURCES[transitionEvent]) {
        if (extSource === 'event') {
          sources.push(def.lookupEvent(this.get(extSource)));
        } else {
          sources.push(def.lookupState(this.get(extSource)));
        }
      }

      for (i = 0; i < sources.length; i++) {
        source = sources[i];
        sourceCallbackNames = source[transitionEvent] || [];

        for (j = 0; j < sourceCallbackNames.length; j++) {
          callbackName = sourceCallbackNames[j];

          if (Ember.typeOf(callbackName) === 'function') {
            callbackFn = callbackName;
            callbackName = '_inline:' + i + '-' + j + '_';
          } else {
            callbackFn = Ember.get(target, callbackName);
            (false && !(Ember.typeOf(callbackFn) === 'function') && Ember.assert('Callback "' + name + '" on target ' + target + ' should be a function, but is a ' + Ember.typeOf(callbackFn), Ember.typeOf(callbackFn) === 'function'));
          }

          callbackVia = source === this ? 'transition' : 'state';

          callbacks.push({
            via: callbackVia,
            name: callbackName,
            fn: (0, _utils.bind)(target, callbackFn),
            key: callbackVia + ':' + callbackName
          });
        }
      }

      return callbacks;
    },
    callback: function callback(name) {
      var _this2 = this;

      var promises = {};
      var callbacks = void 0;
      var callback = void 0;
      var promise = void 0;
      var i = void 0;

      var promiseCallback = function promiseCallback(fn) {
        return (0, _utils.withPromise)(function () {
          if (_this2.get('isAborted')) {
            return 'aborted';
          } else {
            return fn(_this2);
          }
        });
      };

      var callbackPromiseResolver = function callbackPromiseResolver(cb) {
        return function (result) {
          var resolutions = _this2.get('resolutions');

          if (!resolutions[name]) {
            resolutions[name] = {};
          }

          resolutions[name][cb.key] = result;
        };
      };

      var callbackPromiseRejector = function callbackPromiseRejector(cb) {
        return function (error) {
          var rejections = _this2.get('rejections');

          if (!rejections[name]) {
            rejections[name] = {};
          }

          rejections[name][cb.key] = error;

          _this2.set('rejection', error);
        };
      };

      // Shortcut internal callbacks
      if (name[0] === '_') {
        return Ember.RSVP.resolve(this.get('machine')[name](this));
      }

      callbacks = this.callbacksFor(name);

      for (i = 0; i < callbacks.length; i++) {
        callback = callbacks[i];
        promise = promiseCallback(callback.fn);

        promise.then(callbackPromiseResolver(callback), callbackPromiseRejector(callback));

        promises[callback.key] = promise;
      }

      return Ember.RSVP.hash(promises);
    },
    toString: function toString() {
      return 'Transition {\n' + ('  event: ' + this.get('event') + ',\n') + ('  eventArgs: ' + Ember.inspect(this.get('eventArgs')) + ',\n') + ('  fromState: ' + Ember.inspect(this.get('fromState')) + ',\n') + ('  toState: ' + Ember.inspect(this.get('toState')) + ',\n') + ('  isResolved: ' + this.get('isResolved') + ',\n') + ('  isRejected: ' + this.get('isRejected') + '\n') + '}';
    }
  });
});