define('ember-fsm/-stateful', ['exports', 'ember-fsm/-machine'], function (exports, _machine) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    fsmEvents: null,
    fsmStates: null,
    fsmInitialState: null,
    fsmIsLoading: Ember.computed.reads('__fsm__.isTransitioning'),
    fsmCurrentState: Ember.computed.reads('__fsm__.currentState'),

    init: function init() {
      this._super.apply(this, arguments);
      var params = {};
      var mixin = {};
      var fsm = void 0;

      params.target = this;
      params.events = this.get('fsmEvents');
      params.states = this.get('fsmStates');
      params.initialState = this.get('fsmInitialState');

      fsm = _machine.default.create(params);

      this.set('__fsm__', fsm);

      fsm.isInStateAccessorProperties.forEach(function (prop) {
        mixin[prop] = Ember.computed.reads('__fsm__.' + prop);
      });

      this._super.apply(this, arguments);
      this.reopen(mixin);
    },
    sendStateEvent: function sendStateEvent() {
      var fsm = this.get('__fsm__');
      return fsm.send.apply(fsm, arguments);
    }
  });
});