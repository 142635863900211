define("ember-cli-skip-links/templates/components/skip-link-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mliaXcpE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,[[35,0]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isActive\",\"renderContent\",\"if\"]}",
    "moduleName": "ember-cli-skip-links/templates/components/skip-link-body.hbs"
  });

  _exports.default = _default;
});