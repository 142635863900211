define('ember-fsm/utils', ['exports', '@ember/string'], function (exports, _string) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isThenable = isThenable;
  exports.withPromise = withPromise;
  exports.capitalCamelize = capitalCamelize;
  exports.toArray = toArray;
  exports.ownPropertiesOf = ownPropertiesOf;
  exports.isObject = isObject;
  exports.getFirst = getFirst;
  exports.bind = bind;
  exports.contains = contains;
  function isThenable(thing) {
    var thingType = Ember.typeOf(thing);

    if (thingType === 'object' || thingType === 'instance') {
      return Ember.typeOf(Ember.get(thing, 'then')) === 'function';
    } else {
      return false;
    }
  }

  // Takes a function, calls it, then wraps the result in a promise if it's not
  // already a promise. If the function throws an error it is caught and called as
  // the rejector of the created promise.
  function withPromise(block) {
    var response = void 0;
    var exception = void 0;

    try {
      response = block();
    } catch (e) {
      exception = e;
    }

    if (isThenable(response)) {
      return response;
    } else {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (exception) {
          reject(exception);
        } else {
          resolve(response);
        }
      });
    }
  }

  function capitalCamelize(str) {
    return (0, _string.capitalize)((0, _string.camelize)(str));
  }

  function toArray(thing) {
    if (thing === undefined) {
      return [];
    }

    return Ember.typeOf(thing) === 'array' ? thing : [thing];
  }

  function ownPropertiesOf(object) {
    var properties = [];
    var property = void 0;

    if (!isObject(object)) {
      throw new TypeError('can\'t determine properties of: ' + object);
    }

    for (property in object) {
      if (object.hasOwnProperty(property) && object[property] !== undefined) {
        properties.push(property);
      }
    }

    return properties;
  }

  function isObject(obj) {
    var type = Ember.typeOf(obj);
    return type === 'class' || type === 'instance' || type === 'object';
  }

  function getFirst(obj, properties) {
    var value = void 0;
    var i = void 0;

    properties = toArray(properties);

    if (!isObject(obj)) {
      return value;
    }

    for (i = 0; i < properties.length; i++) {
      value = obj[properties[i]];

      if (value !== undefined) {
        break;
      }
    }

    return value;
  }

  function bind(target, fn) {
    return function () {
      return fn.apply(target, arguments);
    };
  }

  function contains(array, item) {
    return array.indexOf(item) >= 0;
  }
});