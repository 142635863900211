define('ember-fsm/-reject', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.reject = reject;
  function reject() {
    throw new Ember.Error('rejected transition');
  }
});